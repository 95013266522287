import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import NavButtons from 'components/Motion_Detection/Alarm_Notification/NavButtons';
import ForumBox from 'components/Motion_Detection/Alarm_Notification/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm Notification",
  "path": "/Motion_Detection/Alarm_Notification/720p_Series/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Setting up and activating the email alarm.",
  "image": "./MD_SearchThumb_AlarmNotification_720p.png",
  "social": "/images/Search/MD_SearchThumb_AlarmNotification_720p.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-Alarm-Notification_white.webp",
  "chapter": "Motion Detection"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Alarm Notification' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Setting up and activating the email alarm.' image='/images/Search/MD_SearchThumb_AlarmNotification_720p.png' twitter='/images/Search/MD_SearchThumb_AlarmNotification_720p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/Alarm_Benachrichtigung/720p_Serie/' locationFR='/fr/Motion_Detection/Alarm_Notification/720p_Series/' crumbLabel="Notifications" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "alarm-notification",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#alarm-notification",
        "aria-label": "alarm notification permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Notification`}</h1>
    <h2 {...{
      "id": "720p-camera-series",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#720p-camera-series",
        "aria-label": "720p camera series permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Camera Series`}</h2>
    <p>{`Setting up and activating the email alarm.`}</p>
    <h3 {...{
      "id": "step-01",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-01",
        "aria-label": "step 01 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 01`}</h3>
    <p>{`In order that the camera can sent you pictures by email you have to save your email providers data inside the camera. This can be done if you open the web interface of your IP camera and then click on `}<strong parentName="p">{`Alarm`}</strong>{` in order to open the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Alarm/SMTP_Server/"
      }}>{`SMTP SERVER`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2336e01d12302e3aa52912bd8360309f/29d31/Email_Einstellungen_HD_01_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.52173913043479%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwACBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHY9Qw5qP/EABsQAAICAwEAAAAAAAAAAAAAAAECAAMREhMh/9oACAEBAAEFArAVOzZDeWVI05JOaz//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAAAARExITJB/9oACAEBAAY/AlySzIpRqUf/xAAdEAACAgEFAAAAAAAAAAAAAAABEQAhYRAxQVHw/9oACAEBAAE/IVO7nmEBkHR7gl5Ri28qex0f/9oADAMBAAIAAwAAABAz7//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/EEf/xAAcEAEBAAICAwAAAAAAAAAAAAABEQAhMUFhkdH/2gAIAQEAAT8QYSlAEu8uCiqTkenWXgFFXHiVDIu3hzoiO5X3AwAa1y5//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2336e01d12302e3aa52912bd8360309f/e4706/Email_Einstellungen_HD_01_EN.avif 230w", "/en/static/2336e01d12302e3aa52912bd8360309f/d1af7/Email_Einstellungen_HD_01_EN.avif 460w", "/en/static/2336e01d12302e3aa52912bd8360309f/70e80/Email_Einstellungen_HD_01_EN.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2336e01d12302e3aa52912bd8360309f/a0b58/Email_Einstellungen_HD_01_EN.webp 230w", "/en/static/2336e01d12302e3aa52912bd8360309f/bc10c/Email_Einstellungen_HD_01_EN.webp 460w", "/en/static/2336e01d12302e3aa52912bd8360309f/426ac/Email_Einstellungen_HD_01_EN.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2336e01d12302e3aa52912bd8360309f/e83b4/Email_Einstellungen_HD_01_EN.jpg 230w", "/en/static/2336e01d12302e3aa52912bd8360309f/e41a8/Email_Einstellungen_HD_01_EN.jpg 460w", "/en/static/2336e01d12302e3aa52912bd8360309f/29d31/Email_Einstellungen_HD_01_EN.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2336e01d12302e3aa52912bd8360309f/29d31/Email_Einstellungen_HD_01_EN.jpg",
              "alt": "Alarm Notification Setup for the 720p Camera Series",
              "title": "Alarm Notification Setup for the 720p Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`All those information will be filled automatically, if you choose the INSTAR SMTP Preset - otherwise:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`SMTP Server`}</strong>{`: Here you have to fill in the SMTP Server from your email provider. Mostly you can find this data on the website of your email provider by searching for SMTP. Otherwise you find a list `}<a parentName="li" {...{
          "href": "/en/Motion_Detection/Alarm_Notification/SMTP_Servers"
        }}>{`Here`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`SMTP Port`}</strong>{`: Fill in the Port which your email provider is using. Normally this will be either Port 25, 465 or 587.`}</li>
      <li parentName="ul"><strong parentName="li">{`SSL`}</strong>{`: Here you can activate if your account is using SSL encryption.`}</li>
      <li parentName="ul"><strong parentName="li">{`SMTP Login Type`}</strong>{`: For almost all email servers this has to be set to Authentication so that you can identify yourself with your username and password.`}</li>
      <li parentName="ul"><strong parentName="li">{`SMTP Username`}</strong>{`: Please fill in the username of your email account.`}</li>
      <li parentName="ul"><strong parentName="li">{`SMTP Password`}</strong>{`: Please fill in the password of your email account.`}</li>
    </ul>
    <p>{`Please enter your email accounts SMTP data and your personal login and save all settings by clicking `}<strong parentName="p">{`Submit`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "470px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1c4b3f8b1b7b3a72f642354b7379d8db/747d8/Email_Einstellungen_HD_02_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "123.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAZABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHsXnoSUEVQIYf/xAAaEAACAgMAAAAAAAAAAAAAAAAAAQJBEBEg/9oACAEBAAEFArHFMvO+f//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABoQAAICAwAAAAAAAAAAAAAAAAEgABAhMUH/2gAIAQEABj8CrICdmiv/xAAaEAEAAwADAAAAAAAAAAAAAAABEBEhACBh/9oACAEBAAE/IQ1C9qfTg05rYA3p/9oADAMBAAIAAwAAABDwyAz/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAeEAEBAQABBAMAAAAAAAAAAAABEQAxEEFRcSHh8P/aAAgBAQABPxBrVg+fvT9DQiRKCzJFwvyEuGmO+UNI9BhPZnkyixPZjg6//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1c4b3f8b1b7b3a72f642354b7379d8db/e4706/Email_Einstellungen_HD_02_EN.avif 230w", "/en/static/1c4b3f8b1b7b3a72f642354b7379d8db/d1af7/Email_Einstellungen_HD_02_EN.avif 460w", "/en/static/1c4b3f8b1b7b3a72f642354b7379d8db/d5421/Email_Einstellungen_HD_02_EN.avif 470w"],
              "sizes": "(max-width: 470px) 100vw, 470px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1c4b3f8b1b7b3a72f642354b7379d8db/a0b58/Email_Einstellungen_HD_02_EN.webp 230w", "/en/static/1c4b3f8b1b7b3a72f642354b7379d8db/bc10c/Email_Einstellungen_HD_02_EN.webp 460w", "/en/static/1c4b3f8b1b7b3a72f642354b7379d8db/4424c/Email_Einstellungen_HD_02_EN.webp 470w"],
              "sizes": "(max-width: 470px) 100vw, 470px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1c4b3f8b1b7b3a72f642354b7379d8db/e83b4/Email_Einstellungen_HD_02_EN.jpg 230w", "/en/static/1c4b3f8b1b7b3a72f642354b7379d8db/e41a8/Email_Einstellungen_HD_02_EN.jpg 460w", "/en/static/1c4b3f8b1b7b3a72f642354b7379d8db/747d8/Email_Einstellungen_HD_02_EN.jpg 470w"],
              "sizes": "(max-width: 470px) 100vw, 470px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1c4b3f8b1b7b3a72f642354b7379d8db/747d8/Email_Einstellungen_HD_02_EN.jpg",
              "alt": "Alarm Notification Setup for the 720p Camera Series",
              "title": "Alarm Notification Setup for the 720p Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-2",
        "aria-label": "step 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2`}</h3>
    <p>{`Next please open the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/HD_Series/Alarm/Email/"
      }}>{`Alarm / Email Overlay`}</a>{` to set the receiver and the sender of the alarm emails.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/27bb2a4aef898659981fed27d62c14c0/29d31/Email_Einstellungen_HD_03_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.52173913043479%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwACBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHY9Qw5qP/EABsQAAICAwEAAAAAAAAAAAAAAAECAAMREhMh/9oACAEBAAEFArAVOzZDeWVI05JOaz//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAACAgMBAAAAAAAAAAAAAAAAARExITJBof/aAAgBAQAGPwJcksyKV6alH//EABwQAAICAgMAAAAAAAAAAAAAAAERACEQYUFR8P/aAAgBAQABPyFTu57hAZB0e4JeUbGraexwv//aAAwDAQACAAMAAAAQE+//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxBH/8QAHBABAQADAAMBAAAAAAAAAAAAAREAITFBcZHR/9oACAEBAAE/EGEpQBLvXARVJ0fHWXgFFXHGkFNHX3ngiO5X7gAAda65/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/27bb2a4aef898659981fed27d62c14c0/e4706/Email_Einstellungen_HD_03_EN.avif 230w", "/en/static/27bb2a4aef898659981fed27d62c14c0/d1af7/Email_Einstellungen_HD_03_EN.avif 460w", "/en/static/27bb2a4aef898659981fed27d62c14c0/70e80/Email_Einstellungen_HD_03_EN.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/27bb2a4aef898659981fed27d62c14c0/a0b58/Email_Einstellungen_HD_03_EN.webp 230w", "/en/static/27bb2a4aef898659981fed27d62c14c0/bc10c/Email_Einstellungen_HD_03_EN.webp 460w", "/en/static/27bb2a4aef898659981fed27d62c14c0/426ac/Email_Einstellungen_HD_03_EN.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/27bb2a4aef898659981fed27d62c14c0/e83b4/Email_Einstellungen_HD_03_EN.jpg 230w", "/en/static/27bb2a4aef898659981fed27d62c14c0/e41a8/Email_Einstellungen_HD_03_EN.jpg 460w", "/en/static/27bb2a4aef898659981fed27d62c14c0/29d31/Email_Einstellungen_HD_03_EN.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/27bb2a4aef898659981fed27d62c14c0/29d31/Email_Einstellungen_HD_03_EN.jpg",
              "alt": "Alarm Notification Setup for the 720p Camera Series",
              "title": "Alarm Notification Setup for the 720p Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Please fill in all necessary data:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Sender`}</strong>{`: Please fill in your email address. This can be the same email address as the receiver.`}</li>
      <li parentName="ul"><strong parentName="li">{`Receiver`}</strong>{`: Please fill in the email address that should receive the alarm message.`}</li>
      <li parentName="ul"><strong parentName="li">{`Email Subject`}</strong>{`: The subject will be shown in every email you receive.`}</li>
      <li parentName="ul"><strong parentName="li">{`Email Text`}</strong>{`: Will be shown together with the alarm pictures in each email.`}</li>
    </ul>
    <p>{`To save all settings please press Submit. You will now be able to test the settings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "470px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d29e2fc9c2581887443ca4a34f448707/747d8/Email_Einstellungen_HD_04_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "123.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAZABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHr6KiCwxrQEMP/xAAbEAACAgMBAAAAAAAAAAAAAAAAAQIREBIgQf/aAAgBAQABBQL0cUzUrDiK+f/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABkQAAIDAQAAAAAAAAAAAAAAAAEgECFBkf/aAAgBAQAGPwKLATet/8QAHRABAAIBBQEAAAAAAAAAAAAAAQARISAxQWGBcf/aAAgBAQABPyHaSJ2p7Jlm4M5jhiLd+IqKp9YfK0f/2gAMAwEAAgADAAAAELANPP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EAB0QAQEBAAICAwAAAAAAAAAAAAERACExEGFxgcH/2gAIAQEAAT8QKnLPdmVL+aMSJQeMNTK+sJJfrDgycCLegwrNHuq5Kc0+cdHn/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d29e2fc9c2581887443ca4a34f448707/e4706/Email_Einstellungen_HD_04_EN.avif 230w", "/en/static/d29e2fc9c2581887443ca4a34f448707/d1af7/Email_Einstellungen_HD_04_EN.avif 460w", "/en/static/d29e2fc9c2581887443ca4a34f448707/d5421/Email_Einstellungen_HD_04_EN.avif 470w"],
              "sizes": "(max-width: 470px) 100vw, 470px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d29e2fc9c2581887443ca4a34f448707/a0b58/Email_Einstellungen_HD_04_EN.webp 230w", "/en/static/d29e2fc9c2581887443ca4a34f448707/bc10c/Email_Einstellungen_HD_04_EN.webp 460w", "/en/static/d29e2fc9c2581887443ca4a34f448707/4424c/Email_Einstellungen_HD_04_EN.webp 470w"],
              "sizes": "(max-width: 470px) 100vw, 470px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d29e2fc9c2581887443ca4a34f448707/e83b4/Email_Einstellungen_HD_04_EN.jpg 230w", "/en/static/d29e2fc9c2581887443ca4a34f448707/e41a8/Email_Einstellungen_HD_04_EN.jpg 460w", "/en/static/d29e2fc9c2581887443ca4a34f448707/747d8/Email_Einstellungen_HD_04_EN.jpg 470w"],
              "sizes": "(max-width: 470px) 100vw, 470px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d29e2fc9c2581887443ca4a34f448707/747d8/Email_Einstellungen_HD_04_EN.jpg",
              "alt": "Alarm Notification Setup for the 720p Camera Series",
              "title": "Alarm Notification Setup for the 720p Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-3",
        "aria-label": "step 3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 3`}</h3>
    <p>{`To receive emails in case of an alarm you will need to activate the motion detection in your camera and select what the camera should do in case of an alarm. All necessary settings are explained `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/Setup/"
      }}>{`Here`}</a>{`.`}</p>
    <p>{`If you need help with any kind of settings, please feel free to contact us any time.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      